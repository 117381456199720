import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {Link} from 'react-router-dom';
const Cta = () => {
    return ( 
        
        <div className="w-full flex items-center justify-center text-white cta bg-white py-5">

            <Container>
                <Row className='justify-content-center py-5 '>
                    <Col md={11} className="d-flex align-items-center justify-content-between py-3 px-5 bg-white shadow-sm rounded-3">
                        <div>
                            <h2 className=' mb-4'>Are you ready to scale your business?</h2>
                            <p className="">Get in touch and let us build something amazing <span className='font-black'>together!</span></p>
                        </div>
                        <div>
                        

                        <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                        Send a message
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
     );
}
 
export default Cta;