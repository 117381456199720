import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Link as ScrollLink } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    // faFacebook,
     faLinkedin, 
    // faTwitter, faXTwitter 
} from '@fortawesome/free-brands-svg-icons';


// const currentYear = new Date().getFullYear();

const Footer = () => {

    const [clickedLink, setClickedLink] = useState(null);

    const handleLinkClick = (linkName) => {
        setClickedLink(linkName);
    };

    return (
        < >
        <footer >
            <div>
                <Container>
                <Row className='justify-content-around py-4'>
                    <Col md={12} className='mb-5'>
                        <div  className=' mb-5'>
                            <h3 className="text-warning mt-4 font-bold text-4xl">ConvSol</h3>
                            <div>
                                <p className='text-dark'>Where Chat Meets AI</p>
                            </div>
                        </div>
                    </Col>

                    <Col md={2}>
                        <div>
                            <h6 className="text-warning  font-bold mb-4">LINKS</h6>
                            <ul className="text-md ps-0">
                                <li className="mb-2"><HashLink to="/" className="inner-foot">About</HashLink></li>
                                <li className="mb-2">
                                    <ScrollLink
                                        to="services"
                                        className={`inner-foot cursor-pointer ${clickedLink === 'services' ? 'clicked' : ''}`}
                                        smooth={true}
                                        duration={500}
                                        onClick={() => handleLinkClick('services')}
                                    >
                                        Services
                                    </ScrollLink>
                                </li>
                                {/* <li className="mb-2"><HashLink smooth to="/blogs" className="inner-foot">Blogs</HashLink></li> */}
                                <li className="mb-2"><HashLink to="/contact" className="inner-foot">Contact</HashLink></li>                            
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                       <div>
                            <h6 className="text-warning  font-bold mb-4">OUR SERVICES</h6>
                            <ul className="text-md">
                                {/* <li className="mb-2"><Link to="/workinprogress"  className="inner-foot">NLP Development</Link></li>
                                <li className="mb-2"><Link to="/workinprogress"  className="inner-foot">Conversational AI Development</Link></li>
                                <li className="mb-2"><Link to="/workinprogress"  className="inner-foot">Cloud Native</Link></li>
                                <li className="mb-2"><Link to="/workinprogress"  className="inner-foot">Machine Learning and Deep Learning</Link></li> */}
                                <li className="mb-2"><span className="inner-foot">NLP Development</span></li>
                                <li className="mb-2"><span className="inner-foot">Conversational AI Development</span></li>
                                <li className="mb-2"><span className="inner-foot">Cloud Native</span></li>
                                <li className="mb-2"><span className="inner-foot">Machine Learning and Deep Learning</span></li>
                            </ul>
                       </div>
                    </Col>

                    <Col md={4}>
                       <div>
                            <h6 className="text-warning text-xl font-bold mb-4">CONTACT</h6>
                            <ul className="text-md">
                                <li className="mb-2"><span  className="inner-foot-social">info@convsol.com</span></li>
                                <li className="mb-2"><span  className="inner-foot-social">Islamabad, Pakistan</span></li>
                                
                                    {/* <a
                                        href="#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="LinkedIn Profile"
                                        className="linkedin-icon text-blue-900 fs-4 me-3"
                                    >
                                        <FontAwesomeIcon icon={faXTwitter} />
                                    </a>


                                    <a
                                        href="#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="LinkedIn Profile"
                                        className="linkedin-icon text-blue-900 fs-4 me-3 ms-2"
                                    >
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </a> */}

                                  
                            </ul>
                       </div>
                    </Col>

                    {/* <Col md={12} className='mt-4 text-center mt-5'>
                        <p>ConvSol &copy; 2023  - All rights reserved.</p>
                    </Col> */}
                </Row>
            </Container>

            <div className='bg-white pt-4 pb-3'>
            <Col md={12} className=' text-center '>
            <a
                                        href="https://www.linkedin.com/company/convsol"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label="LinkedIn Profile"
                                        className="linkedin-icon text-blue-900 fs-4 mt-4 me-3 "
                                    >
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </a>
                        <p className='text-warning mt-2'>ConvSol &copy; 2022 - All rights reserved.</p>
                    </Col>
            </div>
            </div>
            
        </footer>
            {/* <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

            
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                    <div className="col-span-12 lg:col-span-4">
                        <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                            <h3 className="font-bold text-4xl mb-4">ConvSol</h3>
                            <div className='text-md font-medium text-gray-600'>
                                <h5>Diaogue Enhanced Automated</h5>
                                <p>info@convsol.com,</p>
                                <p>Islamabad,</p>
                                <p>Pakistan</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                        <h6 className="text-warning text-xl font-bold mb-4">LINKS</h6>
                        <ul className="text-md ps-0">
                            <li className="mb-2"><HashLink to="/" className="">About</HashLink></li>
                            <li className="mb-2"><HashLink smooth to="/#services" className="">Services</HashLink></li>
                            <li className="mb-2"><HashLink smooth to="/blogs" className="">Blogs</HashLink></li>
                            <li className="mb-2"><HashLink to="/contact" className="">Contact</HashLink></li>                            
                        </ul>
                    </div>

                    <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                        <h6 className="text-warning text-xl font-bold mb-4">OUR SERVICES</h6>
                        <ul className="text-md">
                            <li className="mb-2"><Link to="/workinprogress">NLP Development</Link></li>
                            <li className="mb-2"><Link to="/workinprogress">Conversational AI Development</Link></li>
                            <li className="mb-2"><Link to="/workinprogress">Cloud Native</Link></li>
                            <li className="mb-2"><Link to="/workinprogress">Machine Learning and Deep Learning</Link></li>
                        </ul>
                    </div>
                    <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
                        <div className="text-xl mb-6">
                            Social Media Links.
                        </div>

                                <div className="text-md font-medium mb-6">
                                    Follow us on social media.
                                </div>
                        <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                        <Link to="#" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                        </svg>
                                        </Link>
                                    </li>
                                    <li className="ml-4">
                                        <Link to="#" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                        </svg>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                    </div>          

                    </div>
                </div>
                
            </footer> */}
        </>
    )
}
export default Footer;
