import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import undercons from '../images/underconstruction.jpg'

const WorkInProgress = () => {
  const containerStyle = {
    textAlign: 'center',
    padding: '20px',
  };

  const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
  };

  const descriptionStyle = {
    fontSize: '18px',
    marginBottom: '20px',
  };

  const imageContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
  };

  const interactiveImageStyle = {
    width: '500px',
    height: '500px',
    cursor: 'pointer',
    border: '1px solid #ccc',
    borderRadius: '5px',
    transition: 'transform 0.2s ease-in-out',
  };

  const handleImageClick = () => {
    // Add logic to show a larger version of the image or open a modal with more details.
    console.log("Clicked on the interactive image");
  };

  return (
    <>
    <div>
        <NavBar />
    </div>
    <div style={containerStyle}>
      <h1 style={titleStyle}>Work Under Process</h1>
      <p style={descriptionStyle}>Stay tuned! Exciting things are happening behind the scenes.</p>
      <div style={imageContainerStyle}>
        <img
          src={undercons}
          alt="Work in Progress"
          style={interactiveImageStyle}
          onClick={handleImageClick}
        />
      </div>
    </div>
    <Footer />
    </>
  );
};

export default WorkInProgress;
