

import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link as ScrollLink } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faLinkedin } from '@fortawesome/free-brands-svg-icons';

const NavLinks = () => {
    const [clickedLink, setClickedLink] = useState(null);

    const handleLinkClick = (linkName) => {
        setClickedLink(linkName);
    };

    return (
        <>
            <ScrollLink
                to="about"
                className={`px-4 nav-linkk text-gray-500 hover:text-blue-900 cursor-pointer ${clickedLink === 'about' ? 'clicked' : ''}`}
                smooth={true}
                duration={500}
                onClick={() => handleLinkClick('about')}
            >
                About
            </ScrollLink>
            <ScrollLink
                to="services"
                className={`px-4 nav-linkk text-gray-500 hover:text-blue-900 cursor-pointer ${clickedLink === 'services' ? 'clicked' : ''}`}
                smooth={true}
                duration={500}
                onClick={() => handleLinkClick('services')}
            >
                Services
            </ScrollLink>
            {/* Other links */}

            {/* <HashLink className="px-4 nav-linkk text-gray-500 hover:text-blue-900" to="/blogs">
                Blogs
            </HashLink> */}
            {/* <HashLink className="px-4 nav-linkk text-gray-500 hover:text-blue-900" to="/team">
                Team
            </HashLink> */}
            <HashLink className="px-4 nav-linkk text-gray-500 hover:text-blue-900" to="/contact">
                Contact Us
            </HashLink>

            <a
                href="https://www.linkedin.com/company/convsol"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn Profile"
                className="linkedin-icon text-blue-900 nav-linkkk"
            >
                <FontAwesomeIcon icon={faLinkedin} />
            </a>
        </>
    );
};

export default NavLinks;



